import React from "react";

// reactstrap components
import { Container, 
          Row, 
          Col,
          Card,
          CardBody,
          CardTitle,
          CardFooter,
          Button,
          Modal,
          ModalBody,
          ModalFooter
} from "reactstrap";

// core components

function Servicios() {

  const[Modal1, setModal1] = React.useState(false);
  const[Modal2, setModal2] = React.useState(false);
  const[Modal3, setModal3] = React.useState(false);

  return (
    <>
      <div
        className="section" id="servicios"
        data-background-color="dark-blue"
      >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h2 className="text-center title">
                La Notaría 27 de Bogotá le da la bienvenida  <br></br>
                <a href="/"className="description">
                a su portal web y le ofrece los siguientes servicios.
                </a>
              </h2>
              <div className="space-25"></div>
            </Col>
          </Row>
          <Row>
          <Col md="4">
            <Card className="card-profile" style={{borderRadius: "15px"}}>
              <div className="card-avatar">
                <a href="info" onClick={(e) => e.preventDefault()}>
                  <img
                    alt="Una mano sosteniendo un lapicero firmando un documento"
                    className="img img-raised"
                    src={require("assets/img/notarias/Escritura.jpg")}
                  ></img>
                </a>
              </div>
              <CardBody>
                <CardTitle tag="h2">Escrituración</CardTitle>
                {/* <h6 className="category text-info">Credit Analyst</h6> */}
                <p className="card-description" style={{color:"#605F5F"}}>
                  <b>Documento que contiene la declaración de voluntad de una o varias personas, 
                    emitidas ante el notario, para hacer un determinado contrato o un acto jurídico 
                    individual.
                  </b>
                </p>
                <CardFooter>
                  <Button
                    className="btn-round"
                    color="info"
                    onClick={() => setModal1(true)}
                  >
                    <b style={{fontSize: "16px"}}>Requisitos</b>
                  </Button>
                </CardFooter>
              </CardBody>
            </Card>
            </Col>
            <Col md="4">
              <Card className="card-profile" style={{borderRadius: "15px"}}>
                <div className="card-avatar">
                  <a href="info" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="Un documento sober el registro civil de Colombia"
                      className="img img-raised"
                      src={require("assets/img/notarias/registro_civil.jpg")}
                    ></img>
                  </a>
                </div>
                <CardBody>
                  <CardTitle tag="h2">Registro Civil</CardTitle>
                  {/* <h6 className="category text-info">Credit Analyst</h6> */}
                  <p className="card-description" style={{color:"#605F5F"}}>
                    <b>Es un documento indispensable mediante el cual la persona prueba ante 
                      la familia y la sociedad su existencia y le permite identificarse,
                      ejercer sus derechos y cumplir sus obligaciones.
                    </b>
                  </p>
                  <CardFooter>
                    <Button
                      className="btn-round"
                      color="info"
                      onClick={() => setModal2(true)}
                    >
                      <b style={{fontSize: "16px"}}>Requisitos</b>
                    </Button>
                  </CardFooter>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
            <Card className="card-profile" style={{borderRadius: "15px"}}>
                <div className="card-avatar">
                  <a href="info" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="Una persona colocando un sello a un documento"
                      className="img img-raised"
                      src={require("assets/img/notarias/autentica.jpg")}
                    ></img>
                  </a>
                </div>
                <CardBody>
                  <CardTitle tag="h2">Autenticaciones</CardTitle>
                  {/* <h6 className="category text-info">Credit Analyst</h6> */}
                  <p className="card-description" style={{color:"#605F5F"}}>
                    <b>Es cuando el notario da testimonio escrito de las firma que aparecen
                      en un documento privado fueron puestas en su presencia, 
                      estableciendo la identidad de los firmantes.
                    </b>
                  </p>
                  <CardFooter>
                    <Button
                      className="btn-round"
                      color="info"
                      onClick={() => setModal3(true)}
                    >
                      <b style={{fontSize: "16px"}}>Requisitos</b>
                    </Button>
                  </CardFooter>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* /*
      .##.....##..#######..########.....###....##......
      .###...###.##.....##.##.....##...##.##...##......
      .####.####.##.....##.##.....##..##...##..##......
      .##.###.##.##.....##.##.....##.##.....##.##......
      .##.....##.##.....##.##.....##.#########.##......
      .##.....##.##.....##.##.....##.##.....##.##......
      .##.....##..#######..########..##.....##.########
      */}

      {/* Escrituración */}

      <Modal isOpen={Modal1} toggle={() => setModal1(false)} className="modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White"}}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLiveLabel">
              Requisitos
            </h5>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => setModal1(false)}
            >
              <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i> </span>
            </button>
          </div>
          <ModalBody>
            <p><i className="now-ui-icons arrows-1_minimal-right"></i> Asistir personalmente a la notaría.</p>
            <p><i className="now-ui-icons arrows-1_minimal-right"></i> Llevar cédula de ciudadanía.</p>
            <p><i className="now-ui-icons arrows-1_minimal-right"></i> Llevar documento privado que se va a firmar.</p>
            <p><i className="now-ui-icons arrows-1_minimal-right"></i> Llevar testigo cuando así se requiera.</p>
          </ModalBody>
        </div>
      </Modal>

     {/* Registro Civil */}

      <Modal isOpen={Modal2} toggle={() => setModal2(false)} className="modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White"}}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLiveLabel">
              Requisitos
            </h5>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => setModal2(false)}
            >
              <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
            </button>
          </div>
          <ModalBody>
            <p><i className="now-ui-icons arrows-1_minimal-right"></i> Certificado de nacido vivo.</p>
            <p><i className="now-ui-icons arrows-1_minimal-right"></i> Documento de identificación de los padres.</p>
          </ModalBody>
          <ModalFooter>
            <i>La inscripción del registro civil no tiene costo.</i>
          </ModalFooter>
        </div>
      </Modal>

       {/* Autenticaciones */}

       <Modal isOpen={Modal3} toggle={() => setModal3(false)} className="modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White"}}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLiveLabel">
              Requisitos
            </h5>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => setModal3(false)}
            >
              <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
            </button>
          </div>
          <ModalBody>
          <p><i className="now-ui-icons arrows-1_minimal-right"></i> Asistir personalmente a la notaría. Puede hacerse también a domicilio</p>
            <p><i className="now-ui-icons arrows-1_minimal-right"></i> Llevar cédula de ciudadanía.</p>
            <p><i className="now-ui-icons arrows-1_minimal-right"></i> Llevar documento que se va a autenticar.</p>
          </ModalBody>
        </div>
      </Modal>
    </>
  );
}

export default Servicios;
