import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap";
// core components

// sections for this page
// import Politica from "./Politicas/Politicas.js";


function ClasificadaReservada() {
  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: true,
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }

    // we need to add a script for the github buttons
    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    return function cleanup() {
      document.body.classList.remove("tramites-page");
      document.body.classList.remove("sidebar-collapse");

      // we need to remove the script when we change the page
      script.parentNode.removeChild(script);
    };
  });
  return (
    <>
      <div className="wrapper">
        {/* <Politica /> */}
        <div id="servicio" style={{ backgroundColor: "#f9f9f9" }}>
          <Container className="fluid">
            <Row className="justify-content-center">
              <Col lg="12">
                <h1 className="text-center title" style={{ color: "#031425" }}>
                  ClasificadaReservada <br></br>
                </h1>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Card>
                  <CardBody>
                    <Row className="justify-content-center">
                      <Col md="12">
                        <center>
                          <a href={"https://drive.google.com/file/d/1j-QYkQAo-b3y0Rtybl5f1wnSo57RctcL/view?usp=sharing"} style={{ textDecoration: "none !important" }} target="_black">
                            <img
                              alt="Imagen con referencia Clasificada y Reservada"
                              width="80px"
                              src={require("assets/img/notarias/pdf.png")}
                            ></img>
                            <h6>Informacion Clasificada y Reservada</h6>
                          </a>
                          <a href="http://datos.gov.co/" target="_black">
                            <h6 className="info-title textot" style={{ cursor: "pointer" }}>
                              <b className="subItem">Enlace a Datos Abiertos</b>
                            </h6>
                          </a>
                        </center>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default ClasificadaReservada;
