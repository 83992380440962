import React from "react";
import Pqrs from './pqrs.js';

// reactstrap components
import { Container, 
          Row 
} from "reactstrap";

// core components

function Solicitu() {
  return (
    <>
      <div id= "servicio" style={{backgroundColor: "#f9f9f9"}}>
        <Container className="fluid">
          <Row className="justify-content-center">              
            <Pqrs 
              color= {"#0b4abd"} 
              nombreNotaria={"27 de Bogotá"} 
              terminosNotaria={"/politicas"}>
            </Pqrs>              
          </Row> 
        </Container>
      </div>
    </>
  );
}

export default Solicitu;
