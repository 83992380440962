import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components

// core components

// sections for this page
import CotizadorW from "./Cotizador2/CotizadorW.js";

import {useDispatch} from 'react-redux'
import Axios from 'axios'
import {cargarNotaria} from '../actions/notariaAction'
import {CodigoNotaria, UrlApi} from "../config.js"


function Cotizador() {

  const dispatch = useDispatch()
  
  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: true,
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }

    // we need to add a script for the github buttons
    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    return function cleanup() {
      document.body.classList.remove("tramites-page");
      document.body.classList.remove("sidebar-collapse");

      // we need to remove the script when we change the page
      script.parentNode.removeChild(script);
    };
  });

  React.useEffect(() => {
    
    const consultar = async () =>{

      const resultado = await Axios.get(UrlApi+'notaria/'+CodigoNotaria)

      if(resultado.status === 200){
        await dispatch(cargarNotaria(resultado.data.data))
      }
   }
   consultar()
  });
  return (
    <>
      <div className="wrapper">
        <CotizadorW />
      </div>
    </>
  );
}

export default Cotizador;