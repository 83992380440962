import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
import { Container, 
    Row, 
    Col,
    Card,
    CardBody
} from "reactstrap";
// core components

// sections for this page
// import Politica from "./Miyvi/Miyvi.js";


function Miyvi() {

  // const [tooltipOpen, setTooltipOpen] = useState(false);
  // const toggle = () => setTooltipOpen(!tooltipOpen);
  
  React.useEffect(() => {
    
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: true,
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }

    // we need to add a script for the github buttons
    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    return function cleanup() {
      document.body.classList.remove("tramites-page");
      document.body.classList.remove("sidebar-collapse");

      // we need to remove the script when we change the page
      script.parentNode.removeChild(script);
    };
  });
  return (
    <>
      <div className="wrapper">
        <div id= "servicio" style={{backgroundColor: "#f9f9f9"}}>
          <Container className="fluid">
            <Row className="justify-content-center">
              <Col lg="12">
                <h1 className="title text-center"><b style={{color: "#0b4abd"}}>Misión</b></h1>
              </Col>
            </Row> 
            <Row className="justify-content-center">
              <Col md="10">
                <Card>
                  <CardBody>
                    <h5 className="info text-center">
                      <b>La Notaría Veintisiete del círculo de Bogota D.C.</b> , 
                        está enfocada en mejorar la experiencia en el servicio notarial con excelencia, 
                        confianza, transparencia y seguridad jurídica de todos los actos y hechos jurídicos, 
                        que autorice el notario cumpliendo con los requisitos legales y evitando eventuales controversias.
                    </h5>
                  </CardBody>
                </Card>      
              </Col>
              <Col lg="12">
                <h1 className="title text-center"><b style={{color: "#0b4abd"}}>Visión</b></h1>
              </Col>
              <Col md="10">
                <Card>
                  <CardBody>
                    <h5 className="info text-center">
                      Consolidarnos como Notaria Líder en Colombia, capacitándonos y mejorando continuamente en el desarrollo de la función fedataria.
                    </h5>
                  </CardBody>
                </Card>      
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Miyvi;