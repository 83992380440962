import React from "react";

// reactstrap components
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button, Row, Col
} from "reactstrap";

// core components

function Organigrama(props) {

  return (
    <>
      <Modal isOpen={props.modal} toggle={props.setModal} className="modal-lg modal-dialog modal-dialog-centered" size="lg">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={props.setModal}
            >
              <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Organigrama
            </h3>
            <img
              alt="Organigrama de la Notaría 27 de Bogotá"
              src={require("assets/img/notarias/organigrama.png")}
            ></img>
            <br />
            <Row className="justify-content-center">
              <Col md="6">
                <center>
                  <a href={require("assets/pdf/organigrama.pdf")} style={{ textDecoration: "none !important" }} target="_black">
                    <img
                      alt="Imagen con referencia hacia un icono para descargar un archivo en PDF"
                      width="80px"
                      src={require("assets/img/notarias/pdf.png")}
                    ></img>
                    <h6>Formato descargable</h6>
                  </a>
                </center>
              </Col>
              <Col md="6">
                <center>
                  <a href={require("assets/pdf/manual NOTARIA27.pdf")} style={{ textDecoration: "none !important" }} target="_black">
                    <img
                      alt="Imagen con referencia hacia un icono para descargar un archivo en PDF"
                      width="80px"
                      src={require("assets/img/notarias/pdf.png")}
                    ></img>
                    <h6>Información dependencias</h6>
                  </a>
                </center>
              </Col>
              {/*  <Col md="2">
                      </Col>
                      <Col md="2">
                      </Col>
                      <Col md="2">
                      </Col>
                      <Col md="2">
                      </Col> */}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="info"
              type="button"
              onClick={props.setModal}
              className="text-center"
            >
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
}

export default Organigrama;
