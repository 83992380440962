import React from "react";
import { Link} from "react-router-dom";
// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  NavLink,
  Container
} from "reactstrap";

import Funciones from "../../views/Molda/Funciones.js";
import Organigrama from "../../views/Molda/Organigrama.js";
import Tramites from "../../views/Molda/Tramites.js";

function DropdownWhiteNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const[Modal1,setModal1] = React.useState(false);
  const[Modal2,setModal2] = React.useState(false);
  const[Modal3,setModal3] = React.useState(false);
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="bg-white fixed-top" expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/" tag={Link} id="navbar-brand">
              <img alt="Logo de la notaria 27 de Bogotá"  style={{width: "125px"}} src={require("assets/img/notarias/logo27.png")}></img>
            </NavbarBrand>
            <button
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="ml-auto" id="ceva" navbar>
              <h5>
                <NavLink href="/">
                    <i
                      aria-hidden={true}
                      className="now-ui-icons shopping_shop"
                    ></i>
                    <p style={{fontSize: "9px"}}>Inicio</p>
                </NavLink>
              </h5>
              <UncontrolledDropdown nav>
              <h5>
              <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  href="info"
                  id="navbarDropdownMenuLink1"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                <i  className="now-ui-icons tech_laptop"></i> 
                <p style={{fontSize: "9px"}}>Servicios en linea</p>
                </DropdownToggle>
              </h5>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink1" end>
                  <DropdownItem href="https://documentosnotariales.com/?n=d61e4568-ec50-450f-904c-b478e09d9a1c" target="_blank">
                    <i className="now-ui-icons files_single-copy-04"></i>
                    Solicitud de Copias
                  </DropdownItem>
                  <DropdownItem to="/cotizar" tag={Link}>
                    <i className="now-ui-icons business_money-coins"></i>
                    Cotizador
                  </DropdownItem>
                  <DropdownItem to="/cotizar" tag={Link}>
                    <i className="now-ui-icons business_briefcase-24"></i>
                    Clientes corporativos
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
              <h5>
              <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  href="#"
                  id="navbarDropdownMenuLink"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <i
                    aria-hidden={true}
                    className="now-ui-icons business_badge"
                  ></i>
                  <p style={{fontSize: "9px"}}>Conócenos</p>
                </DropdownToggle>
              </h5>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink" end>
                  <DropdownItem to="/mision_vision" tag={Link}>
                    <i className="now-ui-icons files_single-copy-04"></i>
                    Misión y Visión 
                  </DropdownItem>
                  <DropdownItem  onClick={() => setModal1(true)}>
                    <i className="now-ui-icons users_circle-08"></i>
                    Funciones y deberes
                  </DropdownItem>
                  <DropdownItem to="/procesos" tag={Link}>
                    <i className="now-ui-icons ui-2_settings-90"></i>
                    Procesos y procedemiento
                  </DropdownItem>
                  <DropdownItem onClick={() => setModal2(true)} >
                    <i className="now-ui-icons design_image"></i>
                    Organigrama
                  </DropdownItem>
                  <DropdownItem to="/#cotizador" tag={Link} >
                    <i className="now-ui-icons education_paper"></i>
                    Noticias
                  </DropdownItem>
                  <DropdownItem to="/notario" tag={Link}>
                    <i className="now-ui-icons business_badge"></i>
                    Notario
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
              <h5>
              <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  href="info"
                  id="navbarDropdownMenuLink1"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                <i className="now-ui-icons education_paper"></i> 
                <p style={{fontSize: "9px"}}>TRÁMITES</p>
                </DropdownToggle>
              </h5>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink1" end>
                  <DropdownItem onClick={() => setModal3(true)}>
                    <i className="now-ui-icons education_agenda-bookmark"></i>
                    Información
                  </DropdownItem>
                  <DropdownItem to="/servicio" tag={Link}>
                    <i className="now-ui-icons files_paper"></i>
                    Servicios
                  </DropdownItem>
                  <DropdownItem to="/tarifa" tag={Link}>
                    <i className="now-ui-icons business_money-coins"></i>
                    Tarifas
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <h5>
                  <NavLink href="/#contacto">
                      <i
                        aria-hidden={true}
                        className="now-ui-icons location_pin"
                      ></i>
                      <p style={{fontSize: "9px"}}>Contáctenos</p>
                  </NavLink>
                </h5>
              </NavItem>
              <NavItem>
                <h5>
                  <NavLink href="/transparencia/0">
                      <i
                      aria-hidden={true}
                      className="now-ui-icons ui-1_zoom-bold"
                      ></i>
                      <p style={{fontSize: "9px"}}> TRANSPARENCIA</p>
                  </NavLink>
                </h5>
              </NavItem>
              <NavItem>
                <h5>
                <i
                      aria-hidden={true}
                      className="now-ui-icons business_globe"
                      
                      ></i>
                      <p className="palabra-t" style={{fontSize: "9px"}}>NOTARÍA VIRTUAL</p>
                  {/*<NavLink href="https://notariavirtual.notaria27bogota.co/">
                      <i
                      aria-hidden={true}
                      className="now-ui-icons business_globe"
                      
                      ></i>
                      <p className="palabra-t" style={{fontSize: "9px"}}>NOTARÍA VIRTUAL</p>
                  </NavLink>*/}
                </h5>
              </NavItem>
              <NavItem>
                <h5>
                  <NavLink href="/participa">
                      <i
                      aria-hidden={true}
                      className="now-ui-icons files_paper"
                      ></i>
                      <p style={{fontSize: "9px"}}> PARTICIPA</p>
                  </NavLink>
                </h5>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <Funciones modal={Modal1} setModal={() => setModal1(false)} />
      <Organigrama modal={Modal2} setModal={() => setModal2(false)}/>
      <Tramites modal={Modal3} setModal={() => setModal3(false)}/>
    </>
  );
}

export default DropdownWhiteNavbar;
