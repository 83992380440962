import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardText
} from "reactstrap";
// core components

// sections for this page
// import Politica from "./Notario/Notario.js";


function Notario() {
  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: true,
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }

    // we need to add a script for the github buttons
    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    return function cleanup() {
      document.body.classList.remove("tramites-page");
      document.body.classList.remove("sidebar-collapse");

      // we need to remove the script when we change the page
      script.parentNode.removeChild(script);
    };
  });
  return (
    <>
      <div className="wrapper">
        <div id="servicio" style={{ backgroundColor: "#f9f9f9" }}>
          <Container className="fluid">
            <Row className="justify-content-center">
              <Col lg="12">
                <h1 className="text-center title" style={{ color: "#031425" }}>
                  BIOGRAFÍA DEL NOTARIO <br></br>
                </h1>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Card style={{ borderRadius: "10px" }}>
                  <CardBody>
                    <Row className="justify-content-center">
                      <Col md="4">
                        <Card>
                          <CardBody>
                            <CardText className="text-center" >
                              <b>Doctor Manuel Castro Blanco</b>
                            </CardText>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row className="justify-content-center">
                      <Col md="8">
                        <Row>
                          <Col md="6">
                            <Card style={{ borderRadius: "10px" }}>
                              <CardBody>
                                <h5><b style={{ color: "#031425" }}>País: </b>Colombia</h5>
                                <h5><b style={{ color: "#031425" }}>Departamento: </b>Santander</h5>
                                <h5><b style={{ color: "#031425" }}>Ciudad: </b>Bucaramanga</h5>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col md="6">
                            <Card style={{ borderRadius: "10px" }}>
                              <CardBody>
                                <h5><b style={{ color: "#031425" }}>Correo: </b>notaria27bogota@ucnc.com.co</h5>
                                <h5><b style={{ color: "#031425" }}>Dirección: </b>Carrera 15 N° 75-24 Bogotá, Cundinamarca</h5>
                                <h5><b style={{ color: "#031425" }}>Teléfono: </b>27 6017438826</h5>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Card style={{ borderRadius: "10px" }}>
                              <CardBody>
                                <h4><b style={{ color: "#031425" }}>Estudios</b></h4>
                                <h5><b style={{ color: "#031425" }}>1984 || </b>Abogado</h5>
                                <h5><b style={{ color: "#031425" }}>2004 || </b>Esp. Responsabilidad y Daño Resarcible</h5>
                                <h5><b style={{ color: "#031425" }}>1998 - 2005 || </b>Coordinador de Tierras - Oleoducto Central SA OCENSA</h5>
                                <h5><b style={{ color: "#031425" }}>1985 - 1998 || </b>Asesor Jurídico - Ecopetrol</h5>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Card style={{ borderRadius: "10px" }}>
                              <CardBody>
                                <h4><b style={{ color: "#031425" }}>Experiencia Laboral</b></h4>
                                <h5><b style={{ color: "#031425" }}>Actual      || </b>Notario - Notaría 27 de Bógota</h5>
                                <h5><b style={{ color: "#031425" }}>2005 - 2009 || </b>Consultor Jurídico - Sociedad Minera La Bodega</h5>
                                <h5><b style={{ color: "#031425" }}>1998 - 2005 || </b>Coordinador de Tierras - Oleoducto Central SA OCENSA</h5>
                                <h5><b style={{ color: "#031425" }}>1985 - 1998 || </b>Asesor Jurídico - Ecopetrol</h5>

                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Card style={{ borderRadius: "10px" }}>
                              <CardBody>
                                <h4><b style={{ color: "#031425" }}>Estudios, investigaciones y otras publicaciones</b></h4>
                                <center>
                                  <a href={require("../assets/pdf/libro.pdf")} style={{ textDecoration: "none !important" }} target="_black">
                                    <img
                                      alt="Imagen con referencia hacia un icono para descargar un archivo en PDF"
                                      width="80px"
                                      src={require("assets/img/notarias/pdf.png")}
                                    ></img>
                                    <h6>Manual de servidumbre petrolera</h6>
                                  </a>
                                </center>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Notario;