import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/react-demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";

// pages

import FooterBlack from "components/Footers/FooterBlack.js";
import Whatsapp from "views/presentation-sections/Whatsapp.js";
import PresentationHeader from "components/Headers/PresentationHeader.js";
import DropdownWhiteNavbar from "components/Navbars/DropdownWhiteNavbar.js";
import Tramites from "views/Tramites.js";
import Presentation from "views/Presentation.js";
import Cotizador from "views/Cotizador.js";
import Solicitudes from "views/Solicitudes.js";
import Transparencias from "views/Transparencias.js";

import PoliticasDatosPersonales from "views/PoliticasDatosPersonales";
import PoliticasDerechosAutor from "views/PoliticasDerechosAutor";
import PoliticasPrivacidadWeb from "views/PoliticasPrivacidadWeb";
import CertificadoAccesibilidad from "views/CertificadoAccesibilidad";
import TerminosCondiciones from "views/TerminosCondiciones";
import MapaSitio from "views/MapaSitio";

import Participa from "views/menu-participa/Participa.js";
import Diagnostico from "views/menu-participa/diagnostico-identificacion-problemas.js";
import Planeacion from "views/menu-participa/planeacion-presupuesto-participativo.js";
import Rendicion from "views/menu-participa/rendicion-cuenta.js";
import Consulta from "views/menu-participa/consulta-ciudadana";
import Control from "views/menu-participa/control-social";
import Colaboracion from "views/menu-participa/colaboracion-innovacion-abierta";

import Notario from "views/Notario.js";
import Informacion from "views/Informacion.js";
import Miyvi from "views/Miyvi.js";
import Procesos from "views/Procesos.js";
import Normativa from "views/Normativa.js";
import Tarifas from "views/Tarifas.js";
import Formatos from "views/Formato.js";
import AfectarPublico from "views/AfectarPublico.js";
import Mujeres from "views/Mujeres.js";
import Otrosin from "views/Otrosin.js"; 
import RetencionDocumental from "views/RetencionDocumental.js";
import GestionDocumental from "views/GestionDocumental.js";
import GestionInformacion from "views/GestionInformacion.js";
import ClasificadaReservada from "views/ClasificadaReservada.js";
import Decisiones from "views/Decisiones.js"
import MapaProcesos from "views/MapaProcesos.js";

// others                                                      

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <DropdownWhiteNavbar />
      <PresentationHeader />
      <Switch>
        <Route path="/formato" render={(props) => <Formatos {...props} />} />
        <Route path="/tarifa" render={(props) => <Tarifas {...props} />} />
        <Route path="/normativa" render={(props) => <Normativa {...props} />} />
        <Route path="/procesos" render={(props) => <Procesos {...props} />} />
        <Route path="/mision_vision" render={(props) => <Miyvi {...props} />} />
        <Route path="/informacion" render={(props) => <Informacion {...props} />} />
        <Route path="/notario" render={(props) => <Notario {...props} />} />
        <Route path="/PoliticasDatosPersonales" render={() => <PoliticasDatosPersonales />} />
        <Route path="/PoliticasDerechosAutor" render={() => <PoliticasDerechosAutor />} />
        <Route path="/PoliticasPrivacidadWeb" render={() => <PoliticasPrivacidadWeb />} />
        <Route path="/CertificadoAccesibilidad" render={() => <CertificadoAccesibilidad />} />
        <Route path="/TerminosCondiciones" render={() => <TerminosCondiciones />} />
        <Route path="/MapaSitio" render={() => <MapaSitio />} />

        <Route path="/participa" render={(props) => <Participa {...props} />} />
        <Route
          path="/participacion-para-el-diagnostico-de-necesidades-e-identificacion-de-problemas"
          render={(props) => <Diagnostico {...props} />}
        />
        <Route path="/mujeres" render={(props) => <Mujeres {...props} />} />
        <Route path="/otrosin" render={(props) => <Otrosin {...props} />} />
        <Route path="/control-social" render={(props) => <Control {...props} />} />
        <Route path="/planeacion-y-presupuesto-participativo" render={(props) => <Planeacion {...props} />} />
        <Route path="/consulta-ciudadana" render={(props) => <Consulta {...props} />} />
        <Route path="/rendicion-de-cuentas" render={(props) => <Rendicion {...props} />} />
        <Route path="/control-social" render={(props) => <Control {...props} />} />
        <Route path="/colaboracion-e-innovacion-abierta" render={(props) => <Colaboracion {...props} />} />
        <Route path="/presentation" render={(props) => <Presentation {...props} />} />
        <Route path="/transparencia/:modalNombre" render={(props) => <Transparencias {...props} />} />
        <Route path="/peticiones" render={(props) => <Solicitudes {...props} />} />
        <Route path="/cotizar" render={(props) => <Cotizador {...props} />} />
        <Route path="/servicio" render={(props) => <Tramites {...props} />} />
        <Route path="/AfectarPublico" render={(props) => <AfectarPublico {...props} />} />
        <Route path="/RetencionDocumental" render={(props) => <RetencionDocumental {...props} />} />
        <Route path="/GestionDocumental" render={(props) => <GestionDocumental {...props} />} />
        <Route path="/GestionInformacion" render={(props) => <GestionInformacion {...props} />} />
        <Route path="/ClasificadaReservada" render={(props) => <ClasificadaReservada {...props} />} />
        <Route path="/decisiones" render={(props) => <Decisiones {...props} />} />
        <Route path="/MapaProcesos" render={(props) => <MapaProcesos {...props} />} />
        <Route path="/" render={(props) => <Presentation {...props} />} />
        <Redirect to="/" />
      </Switch>
      <Whatsapp />
      <FooterBlack />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
