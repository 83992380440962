import React from "react";

// reactstrap components
import { Container, 
         Row, 
         Col,
         Carousel, 
         CarouselItem, 
         CarouselIndicators,
         Card,
         CardBody,
         CardHeader
      } from "reactstrap";

// core components
const items = [
  {
    id: 1,
    caption: "Notaria Virtual",
    text: "La prestacion del servicio notarial a través de medios electronicos esta temporalmente fuera de servicio, hasta tanto la Superintendencia de Notariado y Registro emita autorizacion",
    url:"/"
  },
  {
    id: 2,
    caption: "Cotizador online",
    boton: "Cotización",
    text: "En este módulo podrá realizar las cotizaciones previas a sus escrituras de: Compraventa, Compraventa e hipoteca con particulares y/o entidades financieras, Hipotecas, cancelaciones de hipoteca, matrimonios y sucesiones, entre otras.",
    src: require("assets/img/notarias/cotizacion.jpeg"),
    url: "/cotizar"
  }
  
 /*  {
    id: 2,
    caption: "EN PROCESO",
    boton: "EN PROCESO",
    text: ""
  },
  {
    id: 3,
    caption: "EN PROCESO",
    boton: "EN PROCESO",
    text: ""
  } */
];


function Cotizador() {

  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  //const myfunction =() =>{
  //  console.log("CLICKED");
  //}

  return (
    <>
      <div className="section" id="cotizador">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="5">
              <br></br>
            <Card className="text-center" style={{ margin: "10px", marginBottom:"10px", borderRadius: "15px", border: "1px solid #888888"}}>
            {/* paddingTop: "15px", paddingBottom: "15px" */}
              <CardHeader style={{paddingTop: "20px"}}> 
                  <b><i className="now-ui-icons tech_laptop" style={{fontSize: "60px"}}></i></b>
                  {/* <img alt="..." src={require("assets/img/notarias/NotLogo.png")} width="180px" height="80px"></img> */}
              </CardHeader>
              <CardBody>
              <h2 className="title" style={{color: "#031425", paddingTop: "15px"}}>
                  Noticias
              </h2>
              </CardBody>
            </Card>
            <Card className="text-center" style={{margin: "10px", marginBottom:"10px", borderRadius: "15px", border: "1px solid #888888"}}>
            {/* paddingTop: "15px", paddingBottom: "15px" */}
              <CardHeader style={{paddingTop: "10px"}}>
                <h2 className="title" style={{color: "#031425", paddingTop: "1px"}}>
                    Pagos
                </h2> 
                <h5 className="subtitle" style={{ fontSize:"10pt", color: "#031425", paddingTop: "1px"}}>
                    Aquí podrá realizar los pagos de nuestros servicios.
                </h5> 
                <a href="https://api.openpay.co/v1/myrbge2s9nxwb4pmlbby/open-checkout" 
                style={{textDecoration: "none !important"}} target="_black">
                  <img
                  alt="Logo de la aplicación de pagos PSE"
                  width="80px"
                  src={require("assets/img/notarias/pse.png")}
                  ></img>
                </a>
              </CardHeader>
            </Card>
            <Card className="text-center" style={{margin: "10px", marginBottom:"10px", borderRadius: "15px", border: "1px solid #888888"}}>
            {/* paddingTop: "15px", paddingBottom: "15px" */}
              <CardHeader style={{paddingTop: "10px"}}>
                <h2 className="title" style={{color: "#031425", paddingTop: "1px"}}>
                    Contador de Visitas
                </h2> 
                <a href="https://www.contadorvisitasgratis.com" title="contador de visitas para web"><img src="https://counter11.optistats.ovh/private/contadorvisitasgratis.php?c=xs88mn1pfttwfj9czmfrx3hbwzut831k" border="0" title="contador de visitas para web" alt="contador de visitas para web"/></a>
              </CardHeader>
            </Card>
            </Col>
            <Col lg="7">
              <br></br>
              <Carousel activeIndex={activeIndex} next={next} previous={previous} style={{borderRadius: "15px"}}>
                <CarouselIndicators
                  items={items}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {items.map(item => {
                  return (
                    <CarouselItem
                      onExiting={onExiting}
                      onExited={onExited}
                      key={item.id}
                    >
                      <Card className="text-center" style={{margin: "0", width:"100%", paddingRight:"25px", paddingLeft:"25px", borderRadius: "15px"}}>
                        <CardBody>
                        <h2 className="title" style={{color: "Black"}}>{item.caption}</h2>
                        <img alt={item.caption} 
                            src={item.src} 
                            style={{width: "490px", height:"50px", marginBottom: "10px", borderRadius: "8px"}}>
                        </img>
                        <div className="text-center">
                          <p className="card-description" style={{color: "Black"}}><b>{item.text}</b></p>
                          <a href={item.url} className="btn btn-info btn-lg" aria-disabled="true" role="button">
                              <b style={{fontSize: "16px"}}>{item.boton}</b>
                          </a>
                          {/* <Button className="btn-round" color="info" type="button">
                            <b style={{fontSize: "16px"}}>{item.boton}</b>
                          </Button> */}
                        </div>
                        </CardBody>
                      </Card>
                    </CarouselItem>
                  );
                })}
                <a
                  className="carousel-control-prev"
                  data-slide="prev"
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault();
                    previous();
                  }}
                  role="button"
                  style={{width: "10%"}}
                >
                  <i className="now-ui-icons arrows-1_minimal-left" style={{color: "Black"}}></i>
                </a>
                <a
                  className="carousel-control-next"
                  data-slide="next"
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault();
                    next();
                  }}
                  role="button"
                  style={{width: "10%"}}
                >
                  <i className="now-ui-icons arrows-1_minimal-right" style={{color: "Black"}}></i>
                </a>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Cotizador;
