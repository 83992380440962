import React, { useState } from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components

import { useDispatch } from "react-redux";
import Axios from "axios";
import { cargarNotaria } from "../actions/notariaAction";
import "assets/demo/iconosFlotantes.css";

// core components

// sections for this page
import Servicios from "./presentation-sections/Servicios.js";
import Cotizador from "./presentation-sections/Cotizador.js";
import Galeria from "./presentation-sections/Galeria.js";
import Contacto from "./presentation-sections/Contacto.js";
import Whatsapp from "./presentation-sections/Whatsapp.js";

// import "./Presentation.css";

import { Button, Modal } from "reactstrap";

function Presentation() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  React.useEffect(() => {
    const consultar = async () => {
      let config = {
        method: "GET",
        mode: "cors",
        headers: { "Access-Control-Allow-Origin": true, Authorization: "Bearer " + process.env.REACT_APP_TOKEN_API },
      };

      const resultado = await Axios.get(process.env.REACT_APP_UrlApi + "notaria", config);

      if (resultado.status === 200) {
        let dataTemp = {};
        resultado.data.forEach((element) => {
          dataTemp[element.variable] = element.value;
        });
        dispatch(cargarNotaria(dataTemp));
      }
    };
    consultar();
  }, []);

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: true,
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
      //window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    }

    var href = window.location.href.substring(window.location.href.lastIndexOf("#/") + 2);
    var hrefId = href.substring(href.lastIndexOf("#") + 1);
    if (href.lastIndexOf("#") > 0) {
      document.getElementById(hrefId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }

    // we need to add a script for the github buttons
    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    return function cleanup() {
      document.body.classList.remove("presentation-page");
      document.body.classList.remove("sidebar-collapse");

      // we need to remove the script when we change the page
      script.parentNode.removeChild(script);
    };
  });

  React.useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <>
      <div className="wrapper">
        <Servicios />
        <Cotizador />
        <Galeria />
        <Contacto />
        <Whatsapp />
      </div>
      <Modal isOpen={open} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3 style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              La prestacion del servicio notarial a través de medios electronicos esta temporalmente fuera de servicio, hasta tanto la Superintendencia de Notariado y Registro emita autorizacion
            </h3>
          </div>
          <div className="modal-header">
            <h3 style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Realice aquí su descarga en línea
            </h3>
            <button aria-label="Close" className="close" type="button" onClick={() => setOpen(false)}>
              <span aria-hidden={true}>
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </span>
            </button>
          </div>
          <img src={require("assets/img/notarias/copias.jpg")}  alt="copias" width="800px"></img>
          <div style={{ textAlign: "center" }} alt="copias">
            <a
              style={{ fontSize: "17px" }}
              href="https://documentosnotariales.com/?n=d61e4568-ec50-450f-904c-b478e09d9a1c"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button className="btn-round" color="info">
                Ir a Solicitud de Copias
              </Button>
            </a>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Presentation;
