import React from "react";
import "assets/demo/iconosFlotantes.css";

// reactstrap components

// core components
function Whatsapp() {

  return (
    <>
      <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"/>
          <a href="https://wa.me/573208995231"  rel="noopener noreferrer" className="float" target="_blank">
          <i className="fa fa-whatsapp my-float"></i>
        </a>
    </>
  );
}

export default Whatsapp;