import React from "react";

// reactstrap components
import { Container, 
        Row, 
        Col, 
        Carousel, 
        CarouselItem, 
        CarouselIndicators,
        Card,
        CardBody
      } from "reactstrap";

// core components
const items = [
  {
    src: require("assets/img/notarias/oficina.jpg"),
  },
  {
    src: require("assets/img/notarias/oficina2.jpg"),
  },
  {
    src: require("assets/img/notarias/oficina3.jpg"),
  }
];


function Galeria() {

  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  return (
    <>
      <div className="section" id="oficina"
          data-background-color="gray"
      >
        <Container>
          <Row>
            <Col className="text-center ml-auto mr-auto" md="4">
              <Card style={{borderRadius: "15px"}}>
                <CardBody>
                  <blockquote className="blockquote blockquote-info mb-0" style={{borderRadius: "15px"}}>
                    <h2 className="title" style={{color: "Black"}}>Nuestras Oficinas</h2>
                  </blockquote>
                </CardBody>
              </Card>
              <Card style={{borderRadius: "15px"}}>
                <CardBody>
                  <blockquote className="blockquote blockquote-info" style={{borderRadius: "15px"}}>
                    <h4 className="description" style={{color: "Black"}}><b>Tenemos un ambiente adecuado para que usted realice sus trámites.</b></h4>
                  </blockquote>
                </CardBody>
              </Card>
            </Col>
            <Col md="8">
              <Card style={{borderRadius: "15px"}}>
                <CardBody>
                  <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                    <CarouselIndicators
                      items={items}
                      activeIndex={activeIndex}
                      onClickHandler={goToIndex}
                    />
                    {items.map(item => {
                      return (
                        <CarouselItem
                          onExiting={onExiting}
                          onExited={onExited}
                          key={item.src}
                        >
                          <img src={item.src} alt={item.altText} style={{ width: "100%" ,height: "500px"}}/>
                          <div className="carousel-caption d-none d-md-block">
                            <h5>{item.caption}</h5>
                          </div>
                        </CarouselItem>
                      );
                    })}
                    <a
                      className="carousel-control-prev"
                      data-slide="prev"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        previous();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-left"></i>
                    </a>
                    <a
                      className="carousel-control-next"
                      data-slide="next"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        next();
                      }}
                      role="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-right"></i>
                    </a>
                  </Carousel>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Galeria;
